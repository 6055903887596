import isEmpty from 'lodash/isEmpty';

import { I18nText } from '../../../../types';
import { InvoiceItemsTableBlockInvoice } from './InvoiceItemsTableBlock.types';
import { FetchInvoicesCacheKeys, InvoiceStatuses } from '../../invoicesTypes';

import { useInvoiceItemsTableBlock } from './hooks/useInvoiceItemsTableBlock';

import { InvoiceItemsTable } from '../InvoiceItemsTable';
import { InvoiceItemsTableHeader } from './components/InvoiceItemsTableHeader';

interface InvoiceItemsTableBlockProps {
  invoice: InvoiceItemsTableBlockInvoice;
  withGroupBy?: boolean;
  withGroupByProjectsOption?: boolean;
  withGroupByTasksOption?: boolean;
  withGroupByItemCategoriesOption?: boolean;
  withGroupByItemsOption?: boolean;
  withToggleMyItemsOnly?: boolean;
  withoutPayments?: boolean;
  i18nInvoiceTotal?: I18nText;
  withAmountDueCustomFields?: boolean;
  withPrepayment?: boolean;
  canCreateAmountDueCustomFields?: boolean;
  canUpdateAmountDueCustomFields?: boolean;
  canDeleteAmountDueCustomFields?: boolean;
  cacheKeys?: FetchInvoicesCacheKeys;
  isNewItemName?: boolean;
}

function InvoiceItemsTableBlock({
  invoice,
  withGroupBy,
  withGroupByProjectsOption,
  withGroupByTasksOption,
  withGroupByItemCategoriesOption,
  withGroupByItemsOption,
  withToggleMyItemsOnly,
  withoutPayments,
  i18nInvoiceTotal,
  withAmountDueCustomFields,
  withPrepayment,
  canCreateAmountDueCustomFields,
  canUpdateAmountDueCustomFields,
  canDeleteAmountDueCustomFields,
  cacheKeys,
  isNewItemName
}: InvoiceItemsTableBlockProps) {
  const {
    itemsGroupBy,
    toggleGroupBy,
    groupedItems,
    myItemsOnly,
    setMyItemsOnly,
    i18nFirstColName,
    invoiceStatus,
    prepayment,
    prepaymentAmount,
    payments,
    amountDue,
    invoiceTotal,
    isOldItems
  } = useInvoiceItemsTableBlock({
    invoice,
    cacheKeys,
    isNewItemName
  });

  const isEmptyItems =
    isEmpty(invoice.childInvoices) && isEmpty(invoice.tempItems);

  return (
    <>
      {!isEmptyItems && (
        <InvoiceItemsTableHeader
          isDraft={invoice?.status === InvoiceStatuses.DRAFT}
          groupBy={itemsGroupBy}
          onToggleGroupBy={toggleGroupBy}
          myItemsOnly={myItemsOnly}
          onChangeMyItemsOnly={setMyItemsOnly}
          withGroupBy={withGroupBy}
          withGroupByProjectsOption={withGroupByProjectsOption}
          withGroupByTasksOption={withGroupByTasksOption}
          withGroupByItemCategoriesOption={withGroupByItemCategoriesOption}
          withGroupByItemsOption={withGroupByItemsOption}
          withToggleMyItemsOnly={withToggleMyItemsOnly}
        />
      )}
      <InvoiceItemsTable
        selectedCurrency={invoice?.selectedCurrency}
        groupBy={itemsGroupBy}
        amountDue={amountDue}
        items={groupedItems}
        prepayment={prepayment}
        invoiceTotal={invoiceTotal}
        prepaymentAmount={prepaymentAmount}
        i18nFirstColName={i18nFirstColName}
        status={invoiceStatus}
        withoutPayments={withoutPayments}
        payments={payments}
        invoiceCustomFields={invoice?.invoiceCustomFields}
        i18nInvoiceTotal={i18nInvoiceTotal}
        withAmountDueCustomFields={withAmountDueCustomFields}
        withPrepayment={withPrepayment}
        invoiceId={invoice?.id}
        canCreateAmountDueCustomFields={canCreateAmountDueCustomFields}
        canUpdateAmountDueCustomFields={canUpdateAmountDueCustomFields}
        canDeleteAmountDueCustomFields={canDeleteAmountDueCustomFields}
        isOldItems={isOldItems}
        isNewItemName={isNewItemName}
      />
    </>
  );
}

export default InvoiceItemsTableBlock;
