import React, { useCallback } from 'react';

import {
  FETCH_BILLING_INFOS_QUERY,
  FetchBillingInfosQueryResponse
} from '../../../../../queries/fetchBillingInfos.query';

import { useFinPaginatedBillingInfos } from '../../../../../hooks/useFinPaginatedBillingInfos';

import { AddFundsCreateBillingInfoForm } from '../../../../forms/CreateBillingInfoForm';
import { AddFundsModalButtonDataBillingInfo } from '../../../../../../invoices/components/modalButton/AddFundsModalButton';
import { AddFundsModalButtonBillingInfoSelect } from '../../../../../../invoices/components/modalButton/AddFundsModalButton/helpers/AddFundsModalButtonBillingInfoSelect';

import { AddFundsCreateBillingInfoFormProps } from '../../../../forms/CreateBillingInfoForm/AddFundsCreateBillingInfoForm';
import { BillingInfoID } from '../../../../../billingInfosTypes';
import { ErrorMessage, I18nText } from '../../../../../../../types';
import { InvoicePaymentMethods } from '../../../../../../invoices/invoicesTypes';
import { TeamNanoID } from '../../../../../../teams/teamsTypes';

import { AddFundsModalButtonPaymentMethodSelect } from '../../../../../../invoices/components/modalButton/AddFundsModalButton/helpers/AddFundsModalButtonPaymentMethodSelect';
import { Translate } from '../../../../../../../helpers/Translate';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../../../helpers/AlertMessage';

import { BillingInfoCache } from '../../../../../BillingInfoCache';
import {
  billingInfosKeys,
  invoicesKeys
} from '../../../../../../../locales/keys';

interface SelectBillingInfoModalContentProps {
  companyNanoId: TeamNanoID;
  errorMessage?: ErrorMessage;
  i18nText?: I18nText;
  onSelectBillingInfoId?: (billingInfoId?: BillingInfoID) => void;
  onSelectPaymentMethod?: (paymentMethod: InvoicePaymentMethods) => void;
  onSetShowCreateForm: (showCreateForm: boolean) => void;
  paymentMethod?: InvoicePaymentMethods;
  selectedBillingInfoId?: BillingInfoID;
  withCreateForm?: boolean;
  withPaymentMethod?: boolean;
  withSelectForm?: boolean;
  withoutReceiver?: boolean;
  withoutShowCreateFormOption?: boolean;
}

function SelectBillingInfoModalContent({
  addressValidationError,
  avBillingInfoIdValidationError,
  cityValidationError,
  companyNameValidationError,
  vatNumberValidationError,
  companyNanoId,
  contactEmailValidationError,
  firstNameValidationError,
  lastNameValidationError,
  contactPhoneValidationError,
  control,
  countryValidationError,
  errorMessage,
  form,
  i18nText,
  isLoading,
  onSelectBillingInfoId,
  onSelectPaymentMethod,
  onSetShowCreateForm,
  paymentMethod,
  registerAddress,
  registerCity,
  registerCompanyName,
  registerVatNumber,
  registerContactEmail,
  registerFirstName,
  registerLastName,
  registerContactPhone,
  registerZipCode,
  selectedBillingInfoId,
  stateValidationError,
  watchCountry,
  watchPaymentMethod,
  withCreateForm,
  withPaymentMethod,
  withSelectForm,
  withoutReceiver,
  withoutShowCreateFormOption,
  zipCodeValidationError
}: SelectBillingInfoModalContentProps & AddFundsCreateBillingInfoFormProps) {
  const handleSelectBillingInfo = useCallback<
    (billingInfo: AddFundsModalButtonDataBillingInfo) => void
  >(
    (billingInfo) => {
      onSelectBillingInfoId(billingInfo?.id);
      onSetShowCreateForm(false);
    },
    [onSelectBillingInfoId, onSetShowCreateForm]
  );

  const handleShowCreateForm = useCallback<() => void>(() => {
    onSelectBillingInfoId(null);
    onSetShowCreateForm(true);
  }, [onSelectBillingInfoId, onSetShowCreateForm]);

  const { billingInfos, billingInfosLoading, billingInfosErrorMessage } =
    useFinPaginatedBillingInfos<FetchBillingInfosQueryResponse>({
      cacheKey: BillingInfoCache.teamCacheKey(companyNanoId),
      initialLimit: 100,
      initialFilters: {
        companyNanoId: { eq: companyNanoId }
      },
      options: {
        enabled: !!companyNanoId,
        enabledPlaceholder: !!companyNanoId,
        withoutPrefetch: true
      },
      query: FETCH_BILLING_INFOS_QUERY
    });

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex-1 overflow-y-auto px-2 z-0">
        <div className="p-4 space-y-4">
          {i18nText ? (
            <div>
              <Translate id={i18nText} />
            </div>
          ) : null}

          {withPaymentMethod && (
            <AddFundsModalButtonPaymentMethodSelect
              method={paymentMethod}
              onChange={onSelectPaymentMethod}
            />
          )}
          {withSelectForm && (
            <fieldset>
              <legend className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                <Translate id={invoicesKeys.billingInfo} />
              </legend>
              <LoadingSkeleton loaded={!billingInfosLoading}>
                <AddFundsModalButtonBillingInfoSelect
                  anotherBillingInfoI18nText={
                    billingInfosKeys.useAnotherBillingInfo
                  }
                  billingInfos={billingInfos}
                  selectedBillingInfoId={selectedBillingInfoId}
                  showCreateForm={withCreateForm}
                  onBillingInfoSelect={handleSelectBillingInfo}
                  onShowCreateForm={handleShowCreateForm}
                  withPaymentMethod
                  withAvBillingAddress
                  withoutShowCreateFormOption={withoutShowCreateFormOption}
                />
              </LoadingSkeleton>
              <AlertMessage message={billingInfosErrorMessage} />
            </fieldset>
          )}
          {withCreateForm && (
            <fieldset className="space-y-2">
              <AddFundsCreateBillingInfoForm
                addressValidationError={addressValidationError}
                avBillingInfoIdValidationError={avBillingInfoIdValidationError}
                cityValidationError={cityValidationError}
                companyNameValidationError={companyNameValidationError}
                vatNumberValidationError={vatNumberValidationError}
                contactEmailValidationError={contactEmailValidationError}
                firstNameValidationError={firstNameValidationError}
                lastNameValidationError={lastNameValidationError}
                countryValidationError={countryValidationError}
                contactPhoneValidationError={contactPhoneValidationError}
                control={control}
                form={form}
                isLoading={isLoading}
                registerAddress={registerAddress}
                registerCity={registerCity}
                registerCompanyName={registerCompanyName}
                registerVatNumber={registerVatNumber}
                registerContactEmail={registerContactEmail}
                registerFirstName={registerFirstName}
                registerLastName={registerLastName}
                registerContactPhone={registerContactPhone}
                registerZipCode={registerZipCode}
                stateValidationError={stateValidationError}
                watchCountry={watchCountry}
                watchPaymentMethod={watchPaymentMethod}
                withPaymentMethod
                withoutReceiver={withoutReceiver}
                zipCodeValidationError={zipCodeValidationError}
              />

              <div className="px-4">
                <AlertMessage message={errorMessage} />
              </div>
            </fieldset>
          )}
        </div>
      </div>
    </div>
  );
}

export default SelectBillingInfoModalContent;
