import React, { Fragment, ReactNode } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import includes from 'lodash/includes';
import cl from 'classnames';

import {
  InvoiceAmount,
  InvoiceID,
  InvoiceItemsGroupByType,
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceSelectedCurrency,
  InvoiceSmartContractProjectOwnerFullName,
  InvoiceSmartContractProjectOwnerNanoID,
  InvoiceSmartContractTaskCreatedAt,
  InvoiceSmartContractTaskName,
  InvoiceSmartContractTaskNanoID,
  InvoiceSmartContractTaskOwnerFullName,
  InvoiceSmartContractTaskOwnerNanoID,
  InvoiceSmartContractTaskProjectCreatedAt,
  InvoiceSmartContractTaskProjectName,
  InvoiceSmartContractTaskProjectNanoID,
  InvoiceStatus,
  InvoiceStatuses,
  InvoiceTotal
} from '../../invoicesTypes';
import { ItemCategoryName } from '../../../itemCategories/itemCategoriesTypes';
import { Currencies, I18nText, currenciesSymbols, ID } from '../../../../types';
import { ItemPrice, ItemQuantity } from '../../../items/itemsTypes';
import { ItemTypeName } from '../../../itemTypes/itemTypesTypes';
import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  InvoiceCustomFieldOrder
} from '../../../invoiceCustomFields/invoiceCustomFieldsTypes';
import { CustomFieldLocations } from '../../../customFields/customFieldsTypes';

import { InvoiceItemsTableRow } from './components/InvoiceItemsTableRow';

import { MoneyHelper } from '../../../../helpers/MoneyHelper';
import { PercentHelper } from '../../../../helpers/PercentHelper';
import { Translate } from '../../../../helpers/Translate';
import { InvoiceCustomFieldsListViewOrEdit } from '../../../invoiceCustomFields/helpers/InvoiceCustomFieldsListViewOrEdit';

import {
  formsFields,
  invoicesKeys,
  itemCategoriesKeys,
  itemsKeys,
  words
} from '../../../../locales/keys';
import {
  defaultGroupByState,
  paidInvoiceStatuses
} from '../../invoicesConstants';
import {
  ItemTagID,
  ItemTagName,
  ItemTagPosition
} from '../../../itemTags/itemTagsTypes';

export type InvoiceItemType = {
  itemTypeName: ItemTypeName;
  itemCategoryName: ItemCategoryName;
  itemItemTags: {
    id: ID;
    itemTag: {
      id: ItemTagID;
      name: ItemTagName;
    };
    position: ItemTagPosition;
  }[];
  price: ItemPrice;
  quantity: ItemQuantity;
  task?: {
    createdAt: InvoiceSmartContractTaskCreatedAt;
    nanoId: InvoiceSmartContractTaskNanoID;
    name: InvoiceSmartContractTaskName;
    owner: {
      nanoId: InvoiceSmartContractTaskOwnerNanoID;
      fullName: InvoiceSmartContractTaskOwnerFullName;
    };
  };
  project?: {
    createdAt: InvoiceSmartContractTaskProjectCreatedAt;
    nanoId: InvoiceSmartContractTaskProjectNanoID;
    name: InvoiceSmartContractTaskProjectName;
    owner: {
      nanoId: InvoiceSmartContractProjectOwnerNanoID;
      fullName: InvoiceSmartContractProjectOwnerFullName;
    };
  };
  ownerFullName?: string;
};

export type InvoiceItemGroup = {
  items: InvoiceItemType[];
  header?: ReactNode;
};

interface InvoiceItemsTableProps {
  selectedCurrency?: InvoiceSelectedCurrency;
  groupBy?: InvoiceItemsGroupByType;
  amountDue: InvoiceAmount;
  invoiceTotal: InvoiceTotal;
  items: InvoiceItemGroup[];
  prepayment: InvoicePrepayment;
  prepaymentAmount?: InvoicePrepaymentAmount;
  payments?: number;
  i18nFirstColName?: I18nText;
  status: InvoiceStatus;
  invoiceId?: InvoiceID;
  invoiceCustomFields: {
    id: InvoiceCustomFieldID;
    label: InvoiceCustomFieldLabel;
    body: InvoiceCustomFieldBody;
    location: InvoiceCustomFieldLocation;
    order: InvoiceCustomFieldOrder;
  }[];
  i18nInvoiceTotal?: I18nText;
  withoutPayments?: boolean;
  withAmountDueCustomFields?: boolean;
  withPrepayment?: boolean;
  canCreateAmountDueCustomFields?: boolean;
  canUpdateAmountDueCustomFields?: boolean;
  canDeleteAmountDueCustomFields?: boolean;
  isOldItems?: boolean;
  isNewItemName?: boolean;
}

function InvoiceItemsTable({
  selectedCurrency,
  amountDue,
  invoiceTotal,
  items,
  prepayment,
  prepaymentAmount = 0,
  payments,
  i18nFirstColName,
  groupBy = defaultGroupByState,
  status,
  invoiceId,
  invoiceCustomFields,
  i18nInvoiceTotal,
  withoutPayments,
  withAmountDueCustomFields,
  withPrepayment,
  canCreateAmountDueCustomFields,
  canUpdateAmountDueCustomFields,
  canDeleteAmountDueCustomFields,
  isOldItems,
  isNewItemName
}: InvoiceItemsTableProps) {
  const currencyPrefix =
    currenciesSymbols[selectedCurrency] || selectedCurrency || Currencies.USD;

  const tax = 0;

  const isDraft = status === InvoiceStatuses.DRAFT;

  const PaymentsBlock = includes(paidInvoiceStatuses, status) ? (
    <>
      <tr>
        <td></td>
        <td colSpan={2} className="text-xs py-0.5 text-left px-2">
          <Translate id={words.payments} />
        </td>
        <td className="text-xs text-right pl-2 py-0.5">
          <MoneyHelper value={-payments} currency={currencyPrefix} />
        </td>
      </tr>
    </>
  ) : (
    withPrepayment && (
      <>
        <tr>
          <td></td>

          <td colSpan={2} className="text-xs py-0.5 text-left px-2">
            <Translate id={formsFields.prepayment} />
          </td>
          <td className="text-xs text-right pl-2 py-0.5">
            <PercentHelper percent={prepayment} />
          </td>
        </tr>

        <tr>
          <td></td>
          <td colSpan={2} className="text-xs py-0.5 text-left px-2">
            <Translate id={invoicesKeys.prepaymentAmount} />
          </td>
          <td className="text-xs text-right pl-2 py-0.5">
            <MoneyHelper value={prepaymentAmount} currency={currencyPrefix} />
          </td>
        </tr>
      </>
    )
  );

  const isItems = groupBy.items || groupBy.itemCategories || groupBy.itemTags;

  const textStyles = isDraft
    ? 'text-gray-600'
    : 'text-gray-600 dark:text-gray-400';

  const borderStyles = isDraft
    ? 'border-gray-600'
    : 'border-gray-200 dark:border-gray-600';

  return (
    <table className="w-full font-mono">
      {size(items) > 0 ? (
        <thead>
          <tr className={cl('border-b', textStyles, borderStyles)}>
            <th className="text-xs font-semibold py-2 text-left pr-2 w-1/2">
              <div className="flex items-center gap-1.5">
                <Translate
                  id={
                    i18nFirstColName ||
                    (groupBy.items
                      ? itemsKeys.name
                      : groupBy.itemTags
                      ? itemsKeys.itemTags
                      : itemCategoriesKeys.plural)
                  }
                />
              </div>
            </th>
            {!groupBy.itemCategories && !groupBy.itemTags && (
              <th className="text-xs font-semibold text-left py-2 pl-2 whitespace-nowrap">
                <Translate id={groupBy.items ? itemsKeys.price : words.owner} />
              </th>
            )}
            <th className="text-xs font-semibold text-left py-2 pl-2 whitespace-nowrap w-0">
              <Translate
                id={
                  isItems
                    ? itemsKeys.qty
                    : groupBy.tasks
                    ? itemsKeys.taskCreated
                    : itemsKeys.projectCreated
                }
              />
            </th>
            {(groupBy.itemCategories || groupBy.itemTags) && <th></th>}
            <th className="text-xs font-semibold py-2 text-right pl-2">
              <Translate id={isItems ? itemsKeys.subTotal : words.total} />
            </th>
          </tr>
        </thead>
      ) : (
        <thead>
          <tr>
            <th className="pr-2 w-1/2"></th>
            <th className="px-2"></th>
            <th className="w-px"></th>
            <th className="pl-2"></th>
          </tr>
        </thead>
      )}

      <tbody>
        {size(items) > 0 ? (
          map(items, (group, key) => (
            <Fragment key={key}>
              {group.header && (
                <tr
                  className={cl(
                    'text-xs font-bold',
                    isDraft ? 'bg-yellow-200' : 'bg-gray-50 dark:bg-gray-850'
                  )}
                >
                  {group.header}
                </tr>
              )}

              {map(group.items, (item, index) => (
                <InvoiceItemsTableRow
                  selectedCurrency={selectedCurrency}
                  item={item}
                  index={index}
                  length={group.items.length}
                  groupBy={groupBy}
                  key={index}
                  isOldItems={isOldItems}
                  isNewItemName={isNewItemName}
                />
              ))}
            </Fragment>
          ))
        ) : (
          <tr>
            <td
              colSpan={4}
              className={cl('text-md text-center px-2 pb-10', textStyles)}
            >
              <Translate id={invoicesKeys.invoiceHasNoItems} />
            </td>
          </tr>
        )}
      </tbody>

      <tbody className="break-inside-avoid">
        <tr>
          <td></td>
          <td
            colSpan={2}
            className={cl(
              'text-xs text-left px-2 pb-0.5 pt-2 border-t',
              borderStyles
            )}
          >
            <Translate id={invoicesKeys.total} />
          </td>
          <td
            className={cl(
              'text-xs text-right pl-2 pb-0.5 pt-2 border-t',
              borderStyles
            )}
          >
            <MoneyHelper value={invoiceTotal} currency={currencyPrefix} />
          </td>
        </tr>

        {!withoutPayments && PaymentsBlock}

        <tr>
          <td></td>
          <td colSpan={2} className="text-xs pt-0.5 pb-2 text-left px-2">
            <Translate id={words.tax} />
          </td>
          <td className="text-xs text-right pl-2 pt-0.5 pb-2">
            <MoneyHelper value={tax} currency={currencyPrefix} />
          </td>
        </tr>

        <tr>
          <td></td>
          <td
            colSpan={2}
            className={cl(
              'sm:text-lg font-semibold text-left px-2 py-2 border-t',
              borderStyles
            )}
          >
            <Translate id={i18nInvoiceTotal || words.amountDue} />
          </td>
          <td
            className={cl(
              'sm:text-lg text-right pl-0.5 sm:pl-2 font-semibold border-t py-1.5',
              borderStyles
            )}
          >
            <div
              className={cl(
                'inline-block py-0.5 px-0.5 sm:px-2 rounded-md',
                isDraft
                  ? 'bg-yellow-200 border border-yellow-300'
                  : 'bg-yellow-100 dark:bg-yellow-800'
              )}
            >
              <MoneyHelper
                value={
                  status === InvoiceStatuses.PAID && !withoutPayments
                    ? invoiceTotal - payments
                    : amountDue
                }
                currency={currencyPrefix}
              />
            </div>
          </td>
        </tr>

        {withAmountDueCustomFields && (
          <tr>
            <td></td>
            <td colSpan={3} className="px-2 py-2">
              <InvoiceCustomFieldsListViewOrEdit
                inputClassName={
                  isDraft &&
                  'w-full text-xs resize-none bg-transparent border-0 px-2 py-1 -mt-1 rounded text-gray-600'
                }
                customFieldsLocation={CustomFieldLocations.AMOUNT_DUE}
                invoiceId={invoiceId}
                invoiceCustomFields={invoiceCustomFields}
                withoutLocation
                canCreate={canCreateAmountDueCustomFields}
                canUpdate={canUpdateAmountDueCustomFields}
                canDelete={canDeleteAmountDueCustomFields}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
export default InvoiceItemsTable;
