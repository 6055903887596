import cl from 'classnames';

import { Currencies, currenciesSymbols } from '../../../../../../types';
import {
  InvoiceItemsGroupByType,
  InvoiceSelectedCurrency
} from '../../../../invoicesTypes';
import { InvoiceItemType } from '../../InvoiceItemsTable';

import { MoneyHelper } from '../../../../../../helpers/MoneyHelper';
import { DateHelper } from '../../../../../../helpers/DateHelper';
import { NextPureLinkHelper } from '../../../../../../helpers/links/NextPureLinkHelper';

import { TaskPath } from '../../../../../tasks/TaskPath';
import { ProjectPath } from '../../../../../projects/ProjectPath';
import { addItemsTableCellClassName } from '../../../../../items/utils/addItemsTableCellClassName';

interface InvoiceItemsTableRowProps {
  selectedCurrency?: InvoiceSelectedCurrency;
  item: InvoiceItemType;
  index: number;
  length: number;
  groupBy: InvoiceItemsGroupByType;
  isOldItems?: boolean;
  isNewItemName?: boolean;
}

function InvoiceItemsTableRow({
  selectedCurrency,
  item,
  index,
  length,
  groupBy,
  isOldItems,
  isNewItemName
}: InvoiceItemsTableRowProps) {
  const currencyPrefix =
    currenciesSymbols[selectedCurrency] || selectedCurrency || Currencies.USD;

  const entity = groupBy.tasks ? item.task : item.project;

  const entityName = groupBy.tasks ? (
    <NextPureLinkHelper
      className="hover:underline"
      text={item.task?.name}
      href={TaskPath.show(item.task?.nanoId)}
    />
  ) : (
    <NextPureLinkHelper
      className="hover:underline"
      text={item.project?.name}
      href={ProjectPath.messages(item.project?.nanoId)}
    />
  );

  const isItems = groupBy.items || groupBy.itemCategories || groupBy.itemTags;

  return (
    <tr className="group align-top">
      <td
        className={cl(
          'text-xs text-left pr-0.5 sm:pr-2',
          addItemsTableCellClassName(index, length)
        )}
      >
        {groupBy.items
          ? isOldItems || !isNewItemName
            ? item.itemTypeName
            : item.itemItemTags
                .sort((itemItemTag) => itemItemTag.position)
                .map((itemItemTag) => itemItemTag.itemTag.name)
                .join(' ')
          : groupBy.itemCategories
          ? item.itemCategoryName
          : entityName}
      </td>

      {!groupBy.itemCategories && !groupBy.itemTags && (
        <td
          className={cl(
            'text-xs text-left pl-2',
            addItemsTableCellClassName(index, length)
          )}
        >
          {groupBy.items ? (
            <MoneyHelper value={item.price} currency={currencyPrefix} />
          ) : (
            item?.ownerFullName
          )}
        </td>
      )}

      <td
        className={cl(
          'text-xs text-right pl-2 relative pr-0 w-0 whitespace-nowrap',
          addItemsTableCellClassName(index, length)
        )}
      >
        {isItems ? item.quantity : <DateHelper date={entity?.createdAt} />}
      </td>

      {(groupBy.itemCategories || groupBy.itemTags) && <td></td>}

      <td
        className={cl(
          'text-xs text-right pl-2',
          addItemsTableCellClassName(index, length)
        )}
      >
        <MoneyHelper
          value={item.price * (groupBy.items ? item.quantity : 1)}
          currency={currencyPrefix}
        />
      </td>
    </tr>
  );
}

export default InvoiceItemsTableRow;
