import { request } from 'graphql-request';
import get from 'lodash/get';
import includes from 'lodash/includes';

import { GRAPHQL_READ_FIN_API_URI } from '../../../../../../../config';

import { DeleteItemGqlQuery } from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

interface DeleteItemProps<DeleteItemInput> {
  action: string;
  query: DeleteItemGqlQuery;
  queryInput: DeleteItemInput;
}

const successQueryStatuses = ['deleted', 'success'];

export function deleteFinItem<DeleteItemInput, DeleteItemResponse>({
  action,
  query,
  queryInput
}: DeleteItemProps<DeleteItemInput>) {
  const { rawName, name } = getQueryName(query, 'mutation');

  productionConsole(
    'log',
    'deleteFinItem',
    { action, queryInput },
    rawName,
    JSON.stringify(queryInput)
  );

  const requestId = generateUuid();

  return new Promise<DeleteItemResponse>((resolve, reject) => {
    return request<DeleteItemResponse, DeleteItemInput>(
      `${GRAPHQL_READ_FIN_API_URI}?mutationName=${name}&requestId=${requestId}`,
      query,
      queryInput,
      { requestId }
    )
      .then((response) => {
        if (includes(successQueryStatuses, get(response, `${action}.status`))) {
          return resolve(response);
        }

        reject(get(response, `${action}.errors`));
      })
      .catch((reason) => reject(reason));
  });
}
